import moment from 'moment';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export const statesOptions = [
  { value: '', text: 'State' },
  { value: 'AL', text: 'Alabama' },
  { value: 'AK', text: 'Alaska' },
  { value: 'AS', text: 'American Samoa' },
  { value: 'AZ', text: 'Arizona' },
  { value: 'AR', text: 'Arkansas' },
  { value: 'CA', text: 'California' },
  { value: 'CO', text: 'Colorado' },
  { value: 'CT', text: 'Connecticut' },
  { value: 'DE', text: 'Delaware' },
  { value: 'DC', text: 'District Of Columbia' },
  { value: 'FM', text: 'Federated States Of Micronesia' },
  { value: 'FL', text: 'Florida' },
  { value: 'GA', text: 'Georgia' },
  { value: 'GU', text: 'Guam' },
  { value: 'HI', text: 'Hawaii' },
  { value: 'ID', text: 'Idaho' },
  { value: 'IL', text: 'Illinois' },
  { value: 'IN', text: 'Indiana' },
  { value: 'IA', text: 'Iowa' },
  { value: 'KS', text: 'Kansas' },
  { value: 'KY', text: 'Kentucky' },
  { value: 'LA', text: 'Louisiana' },
  { value: 'ME', text: 'Maine' },
  { value: 'MH', text: 'Marshall Islands' },
  { value: 'MD', text: 'Maryland' },
  { value: 'MA', text: 'Massachusetts' },
  { value: 'MI', text: 'Michigan' },
  { value: 'MN', text: 'Minnesota' },
  { value: 'MS', text: 'Mississippi' },
  { value: 'MO', text: 'Missouri' },
  { value: 'MT', text: 'Montana' },
  { value: 'NE', text: 'Nebraska' },
  { value: 'NV', text: 'Nevada' },
  { value: 'NH', text: 'New Hampshire' },
  { value: 'NJ', text: 'New Jersey' },
  { value: 'NM', text: 'New Mexico' },
  { value: 'NY', text: 'New York' },
  { value: 'NC', text: 'North Carolina' },
  { value: 'ND', text: 'North Dakota' },
  { value: 'MP', text: 'Northern Mariana Islands' },
  { value: 'OH', text: 'Ohio' },
  { value: 'OK', text: 'Oklahoma' },
  { value: 'OR', text: 'Oregon' },
  { value: 'PW', text: 'Palau' },
  { value: 'PA', text: 'Pennsylvania' },
  { value: 'PR', text: 'Puerto Rico' },
  { value: 'RI', text: 'Rhode Island' },
  { value: 'SC', text: 'South Carolina' },
  { value: 'SD', text: 'South Dakota' },
  { value: 'TN', text: 'Tennessee' },
  { value: 'TX', text: 'Texas' },
  { value: 'UT', text: 'Utah' },
  { value: 'VT', text: 'Vermont' },
  { value: 'VI', text: 'Virgin Islands' },
  { value: 'VA', text: 'Virginia' },
  { value: 'WA', text: 'Washington' },
  { value: 'WV', text: 'West Virginia' },
  { value: 'WI', text: 'Wisconsin' },
  { value: 'WY', text: 'Wyoming' },
];

export const validateFormData = (data) => {
  const dataList = Object.entries(data);
  return dataList.reduce((acc, cur) => {
    if (cur[0] === 'phone' && cur[1] === '') {
      return acc;
    }
    const validationResult = isValidData(cur);
    if (!validationResult[0]) {
      acc[cur[0]] = getErrorsMsg(validationResult[1]);
    }
    return acc;
  }, []);
};

const isValidData = (data) => {
  if (data[1]) {
    switch (data[0]) {
      case 'phone':
        return [
          validatePhone(data[1]),
          'phone',
        ];
      case 'birthday':
        if (data[1] !== 'Invalid date') {
          const validBirthday = [true, ''];
          return [validBirthday[0], validBirthday[1]];
        }
        return [false, 'birthdayNotValid'];
      case 'state':
        return [data[1] !== '' || false, 'state'];
      case 'zipcode':
        return [data[1].length === 5 || false, 'zipcode'];
      case 'city':
        return [(data[1].length >= 2 && data[1].length < 21) || false, 'city'];
      case 'address1':
        return [
          (data[1].length >= 3 && data[1].length < 61) || false,
          'address1',
        ];
      case 'address2':
        return [true, ''];
      case 'firstName':
        return [data[1].length >= 1 && data[1].length < 51, 'firstName'];
      case 'lastName':
        return [data[1].length >= 1 && data[1].length < 51, 'lastName'];
      case 'email':
        return [data[1] !== '' || false, 'email'];
      default:
        return [true, ''];
    }
  }
  if (!data[1] && data[0] === 'address2') {
    return [true, ''];
  }
  return [false, data[0]];
};

export const sanitizeNames = (data) => data.replace(/[^A-Za-z\s-']/g, '');

export const sanitizeAddress = (data) => data.replace(/[^A-Za-z0-9\s-.,#']/g, '');

export const sanitizePhone = (data) => data.replace(/\D/g, '');

const validatePhone = (data) => {
  if (data === '' || data === undefined) {
    return true; // Return true if the phone number is empty
  }
  const regData = data.replace(/\D/g, '');
  return phoneUtil.isValidNumber(phoneUtil.parse(regData, 'US'));
};

export const isValidBirthday = (data, type) => {
  const birthday = data;
  if (!birthday.isValid()) {
    return [false, 'birthdayNotValid'];
  }
  if (birthday.isAfter(new Date())) {
    return [false, 'birthdayNotValid'];
  }
  const eighteenYearsAgo = moment().subtract(18, 'years');
  const hundredFiftyYearsAgo = moment().subtract(150, 'years');

  if (type === 'account' && birthday.isAfter(eighteenYearsAgo)) {
    return [false, 'birthdayUnder'];
  }
  if (birthday.isBefore(hundredFiftyYearsAgo)) {
    return [false, 'birthdayNotValid'];
  }
  return [true, ''];
};

export const formatPhoneNumber = (data) => {
  if (validatePhone(data)) {
    if (data === '' || data === undefined) {
      return '';
    }
    if (data.length > 9) {
      const match = data.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
    }
  }
  return data;
};

const getErrorsMsg = (input) => {
  switch (input) {
    case 'phone':
      return 'Not valid phone number, e.g. (XXX) XXX-XXXX';
    case 'birthdayNotValid':
    case 'birthday':
      return 'Submit a valid birthday';
    case 'birthdayUnder':
      return 'Only adults can register';
    case 'state':
      return 'Please select a state';
    case 'zipcode':
      return 'Not valid zip code';
    case 'city':
      return 'Submit a valid city';
    case 'address1':
      return 'Submit a valid address';
    case 'address2':
      return 'Submit a valid address';
    case 'firstName':
      return 'Submit a valid first name';
    case 'lastName':
      return 'Submit a valid last name';
    case 'email':
      return 'Submit a valid email';
    default:
      return 'Not valid input';
  }
};

export const getMainAccountPatientId = (familyMembers) => {
  if (familyMembers) {
    const patient = familyMembers.find((x) => x.patient.isMaster === true);
    return patient.patient.id ? patient.patient.id : null;
  }
  return null;
};
