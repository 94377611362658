import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { DayPicker } from 'react-day-picker';
import { format, isBefore, isAfter } from 'date-fns';
import moment from 'moment';
import { toast } from 'react-toastify';
import StepHeader from './AppointmentStepsHeader';
import 'react-day-picker/dist/style.css';
import '../../assests/sass/partials/_classes.scss';
import StepsButton from './StepsButton';
import AppointmentStepsHeaderNav from './AppointmentStepsHeaderNav';
import {
  selectMorning,
  selectNoon,
  selectEvening,
  selectDateTo,
  selectDateFrom,
  getOpeningTimes,
} from '../../actions/appointmentActions';
import Results from './Time';

function DateAndTime({
  dispatch,
  themeColor,
  request,
  requestService,
  history,
  token,
  fetchingTime,
}) {
  const applyButtonStyle = {
    background: themeColor,
    color: '#fff',
    width: '100px',
    borderRadius: '20px',
  };
  const [state, setState] = useState({
    from: request.dateFrom !== 'null' && request.dateFrom !== null ? new Date(request.dateFrom) : null,
    to: request.dateTo !== 'null' && request.dateTo !== null ? new Date(request.dateTo) : null,
    enteredTo: request.dateTo !== 'null' && request.dateTo !== null ? new Date(request.dateTo) : null,
    scheduleLimitDate: moment().add(parseInt(request.serviceScheduleLimit, 0), 'days'),
    preferredMorning: request.preferredMorning,
    preferredNoon: request.preferredNoon,
    preferredEvening: request.preferredEvening,
  });
  const {
    from,
    enteredTo,
    scheduleLimitDate,
  } = state;

  const modifiers = { start: from, end: enteredTo };
  const disabledDays = {
    after: new Date(scheduleLimitDate),
    before: new Date(),
  };

  const selectedDays = [from, { from, to: enteredTo }];
  const [range, setrange] = useState();
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  useEffect(() => {
    if (request.preferredMorning !== state.preferredMorning) {
      dispatch(selectMorning(state.preferredMorning));
    }
    if (request.preferredNoon !== state.preferredNoon) {
      dispatch(selectNoon(state.preferredNoon));
    }
    if (request.preferredEvening !== state.preferredEvening) {
      dispatch(selectEvening(state.preferredEvening));
    }
  }, [dispatch, request, state]);

  const isSelectingFirstDay = (start, end, day) => {
    const firstDayIsNotSelected = !start;
    const selectedDayIsBeforeFirstDay = start && isBefore(day, start);
    const rangeIsSelected = start && end;
    return (
      firstDayIsNotSelected || selectedDayIsBeforeFirstDay || rangeIsSelected
    );
  };

  const handleDayClick = (day) => {
    const { from, to } = state;

    if (from && to && day >= from && day <= to) {
      reset();
      return;
    }

    if (isSelectingFirstDay(from, to, day)) {
      setState({
        ...state,
        from: day,
        to: null,
        enteredTo: null,
      });
      dispatch(selectDateFrom(moment(day)));
    } else {
      setState({
        ...state,
        to: day,
        enteredTo: day,
      });
      dispatch(selectDateTo(moment(day)));
    }
  };

  const handleDayMouseEnter = (day) => {
    const { from, to } = state;
    if (!isSelectingFirstDay(from, to, day)) {
      setState({
        ...state,
        enteredTo: day,
      });
    }
  };

  const reset = () => {
    setState((prevstate) => ({
      ...prevstate,
      to: null,
      from: null,
    }));
    setrange(null);
    dispatch(selectDateFrom(null));
    dispatch(selectDateTo(null));
  };

  const handleFromChange = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, 'y-MM-dd', new Date());
    if (!isValid(date)) {
      return setrange(null);
    }
    if (range?.to && isAfter(date, range.to)) {
      setrange({ from: range.to, to: date });
    } else {
      setrange({ from: date, to: range?.to });
    }
    return null;
  };

  const handleToChange = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, 'y-MM-dd', new Date());

    if (!isValid(date)) {
      return setrange({ from: range?.from, to: null });
    }
    if (range?.from && isBefore(date, range.from)) {
      setrange({ from: date, to: range.from });
    } else {
      setrange({ from: range?.from, to: date });
    }
    return null;
  };

  const handleRangeSelect = (range) => {
    setrange(range);
    if (range?.from) {
      setFromValue(format(range.from, 'y-MM-dd'));
    } else {
      setFromValue('');
    }
    if (range?.to) {
      setToValue(format(range.to, 'y-MM-dd'));
    } else {
      setToValue('');
    }
  };
  const applyOpenings = (e) => {
    sessionStorage.setItem('pressed', 'true');
    e.preventDefault();
    if (range && isBefore(range.from, range.to)) {
      dispatch(getOpeningTimes(request, token, history));
    } else {
      toast.warning('Please Select Dates');
    }
  };
  const _ga = sessionStorage.getItem('_ga') ? sessionStorage.getItem('_ga') : '';

  if (!requestService.serviceId) {
    return <Redirect to={`/services${_ga}`} />;
  }
  if (!request.providerId) {
    return <Redirect to={`/providers${_ga}`} />;
  }
  const noDays = {
    color: themeColor, padding: '0 20%', fontWeight: 'bold', fontSize: '1rem', marginTop: '20px', textAlign: 'center',
  };

  return (
    <div>
      <div className="steps-container">
        <Row className="g-0 flex-shrink-0">
          <Col md={3} lg={3} sm={12}>
            <AppointmentStepsHeaderNav />
          </Col>
          <Col md={9} lg={9} sm={12}>
            <div className="main-content">
              <StepHeader
                title="Select a day and time"
                description="Please select a range of days and times convenient for you"
              />
              <Row className="mt-5">
                <Col md={12} lg={6}>
                  <div className="Card">
                    <Row className="justify-content-center align-items-center">
                      <Col md={6} sm={12}>
                        <p className="m-0">Select your date range</p>
                      </Col>
                      <Col md={6} sm={12}>
                        <form className="dates d-flex g-2 justify-content-space-between align-items-center">
                          <input
                            size={10}
                            placeholder="From Date"
                            value={fromValue}
                            onChange={handleFromChange}
                            className="input form-control"
                            readOnly
                            style={{ fontSize: '12px' }}
                          />
                          {' – '}
                          <input
                            className="input form-control"
                            size={10}
                            placeholder="To Date"
                            value={toValue}
                            onChange={handleToChange}
                            readOnly
                            style={{ fontSize: '12px' }}
                          />
                        </form>
                      </Col>
                    </Row>
                    <div>
                      <DayPicker
                        className="dayPicker appointment"
                        mode="range"
                        selected={range}
                        onSelect={handleRangeSelect}
                        selectedDays={selectedDays}
                        disabled={disabledDays}
                        modifiers={modifiers}
                        onDayClick={handleDayClick}
                        onDayMouseEnter={handleDayMouseEnter}
                        numberOfMonths={2}
                      />
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <button
                        onClick={reset}
                        type="button"
                        className="btn me-3"
                        style={{ cursor: 'pointer', color: themeColor }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn"
                        style={applyButtonStyle}
                        type="submit"
                        onClick={applyOpenings}
                      >
                        Submit
                      </button>
                    </div>

                  </div>
                </Col>
                <Col md={12} lg={6}>
                  <div className="result-scroll-div h-100 d-flex justify-content-center align-items-stretch">
                    {fetchingTime ? (
                      <div style={{ color: themeColor }}>
                        <div className="fancy-spinner">
                          <div className="ring" />
                          <div className="ring" />
                          <div className="dot" />
                        </div>
                        <p style={noDays}>Searching available appointments</p>
                      </div>
                    ) : <Results />}
                  </div>
                </Col>
              </Row>
              <StepsButton />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

DateAndTime.propTypes = {
  dispatch: PropTypes.func.isRequired,
  themeColor: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
  requestService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  fetchingTime: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  request: state.appointmentReducer.request,
  requestService: state.serviceReducer.request,
  token: state.userReducer.token,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fetchingTime: state.appointmentReducer.fetchingTime,
});

export default withRouter(connect(mapStateToProps)(DateAndTime));
