import {
  FETCH_CLINIC,
  FETCH_CLINIC_REJECTED,
  FETCH_CLINIC_FULFILLED,
  USER_AUTH_SUCCESS,
  USER_AUTH_FAILURE,
  USER_LOGOUT_SUCCESS,
  USER_REGISTRY_FAILURE,
  USER_REGISTRY_SUCCESS,
  FETCH_SEND_APPOINTMENT_FULFILLED,
  FETCH_OPENING_TIME_REJECTED,
  FETCH_SEND_APPOINTMENT_REJECTED,
  ADD_USER_FAMILY_MEMBER_FULFILLED,
  ADD_USER_FAMILY_MEMBER_REJECTED,
  EDIT_USER_FAMILY_MEMBER_FULFILLED,
  EDIT_USER_FAMILY_MEMBER_REJECTED,
  DELETE_USER_FAMILY_MEMBER_FULFILLED,
  DELETE_USER_FAMILY_MEMBER_REJECTED,
  EDIT_INFO_USER_FULFILLED,
  EDIT_INFO_USER_REJECTED,
  CHANGE_AVATAR_USER_FULFILLED,
  CHANGE_AVATAR_USER_REJECTED,
  FETCH_UPDATE_PASSWORD_FULFILLED,
  FETCH_UPDATE_PASSWORD_REJECTED,
  CLEAR_ERRORS,
  CHECK_USER_STATUS_SUCCESS,
  CHECK_USER_STATUS_FAILURE,
  VALIDATE_METHOD_SUCCESS,
  VALIDATE_METHOD_FAILURE,
  USER_REGISTRY_VALIDATE_PROCESS_START,
  USER_REGISTRY_VALIDATE_PROCESS_END,
  CONFIRM_METHOD_SUCCESS,
  CONFIRM_METHOD_FAILURE,
  WRONG_PIN_MESSAGE_SHOW,
  FETCH_PRACTICES_FULFILLED,
  FETCH_PRACTICES_REJECTED,
  GET_EXTERNAL_TOKEN,
} from '../constants';
import message from '../utils/message';

/* TODO move user to userReducer and rewrite logic */

export default (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_CLINIC: {
      return {
        ...state,
        fetching: true,
        practicesList: [],
        practicesOptions: [],
      };
    }

    case FETCH_CLINIC_REJECTED: {
      return {
        ...state,
        fetching: false,
        errors: action.payload,
        messageNotificationError: '404',
        messageNotificationErrorOuter: 'Page Not Found',
        practicesList: [],
        practicesOptions: [],
      };
    }

    case FETCH_CLINIC_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        clinic: action.payload.clinicInfo.clinic,
        services: action.payload.clinicInfo.services,
        providers: action.payload.clinicInfo.providers,
      };
    }

    case USER_AUTH_SUCCESS: {
      return {
        ...state,
        errors: {},
      };
    }

    case USER_AUTH_FAILURE: {
      return {
        ...state,
      };
    }

    case USER_REGISTRY_SUCCESS: {
      return {
        ...state,
        messageNotificationSuccess: 'Successfully registered. Please login.',
        errors: {},
      };
    }

    case USER_REGISTRY_FAILURE: {
      return {
        ...state,
        errors: action.payload.data.errors,
        messageNotificationError: message(
          state.messageNotificationError,
          'Registration failed',
        ),
      };
    }

    case FETCH_OPENING_TIME_REJECTED: {
      return {
        ...state,
        messageNotificationError:
          state.messageNotificationError !== action.payload.Message
          	? action.payload.Message
          	: `${state.messageNotificationError} `,
      };
    }

    case FETCH_SEND_APPOINTMENT_FULFILLED: {
      return {
        ...state,
      };
    }

    case FETCH_SEND_APPOINTMENT_REJECTED: {
      return {
        ...state,
        messageNotificationError: message(
          state.messageNotificationError,
          'The appointment was not confirmed by the system',
        ),
      };
    }

    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        errors: {},
      };
    }

    case ADD_USER_FAMILY_MEMBER_FULFILLED: {
      return {
        ...state,
        messageNotificationSuccess: message(
          state.messageNotificationSuccess,
          'Successfully added family member',
        ),
        errors: {},
      };
    }

    case ADD_USER_FAMILY_MEMBER_REJECTED: {
      return {
        ...state,
        messageNotificationError: message(
          state.messageNotificationError,
          'Unable to add family member',
        ),
        errors: action.payload.data.errors,
      };
    }

    case DELETE_USER_FAMILY_MEMBER_FULFILLED: {
      return {
        ...state,
        messageNotificationSuccess: message(
          state.messageNotificationSuccess,
          'Successfully deleted family member',
        ),
      };
    }

    case DELETE_USER_FAMILY_MEMBER_REJECTED: {
      return {
        ...state,
        messageNotificationError: message(
          state.messageNotificationError,
          'Unable to delete family member',
        ),
      };
    }

    case EDIT_USER_FAMILY_MEMBER_FULFILLED: {
      return {
        ...state,
        messageNotificationSuccess: message(
          state.messageNotificationSuccess,
          'Successfully updated family member',
        ),
        errors: {},
      };
    }

    case EDIT_USER_FAMILY_MEMBER_REJECTED: {
      return {
        ...state,
        errors: action.payload.data.errors,
        messageNotificationError: message(
          state.messageNotificationError,
          'Unable to update family member',
        ),
      };
    }

    case EDIT_INFO_USER_FULFILLED: {
      return {
        ...state,
        errors: {},
      };
    }

    case EDIT_INFO_USER_REJECTED: {
      return {
        ...state,
        messageNotificationError: message(
          state.messageNotificationError,
          'Unable to update profile info',
        ),
        errors: action.payload.data.errors,
      };
    }

    case CHANGE_AVATAR_USER_FULFILLED: {
      return {
        ...state,
        messageNotificationSuccess: message(
          state.messageNotificationSuccess,
          'Successfully updated profile photo',
        ),
      };
    }

    case CHANGE_AVATAR_USER_REJECTED: {
      return {
        ...state,
        messageNotificationError: message(
          state.messageNotificationError,
          'Unable to update profile photo',
        ),
      };
    }

    case FETCH_UPDATE_PASSWORD_FULFILLED: {
      return {
        ...state,
        messageNotificationSuccess: message(
          state.messageNotificationSuccess,
          'Successfully updated password',
        ),
      };
    }

    case FETCH_UPDATE_PASSWORD_REJECTED: {
      return {
        ...state,
        messageNotificationError: message(
          state.messageNotificationError,
          'Unable to update password',
        ),
      };
    }

    case CLEAR_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case CHECK_USER_STATUS_SUCCESS: {
      return {
        ...state,
        messageNotificationSuccess:
          'Your information match an existing record. Please login.',
        errors: {},
      };
    }

    case CHECK_USER_STATUS_FAILURE: {
      return {
        ...state,
        errors: action.payload.data.errors,
        messageNotificationError: message(
          state.messageNotificationError,
          'Registration failed',
        ),
      };
    }

    case USER_REGISTRY_VALIDATE_PROCESS_START: {
      return {
        ...state,
        messageNotificationSuccess: 'User registry process approved.',
        errors: {},
      };
    }

    case USER_REGISTRY_VALIDATE_PROCESS_END: {
      return {
        ...state,
        messageNotificationSuccess: 'You have been successfuly registered',
        errors: {},
      };
    }

    case VALIDATE_METHOD_SUCCESS: {
      return {
        ...state,
        messageNotificationSuccess: 'Please select a method for validation.',
        errors: {},
      };
    }

    case VALIDATE_METHOD_FAILURE: {
      return {
        ...state,
        errors: action.payload.data.errors,
        messageNotificationError: message(
          state.messageNotificationError,
          'Validate method failed',
        ),
      };
    }

    case CONFIRM_METHOD_SUCCESS: {
      return {
        ...state,
        messageNotificationSuccess: 'Please submit confirmation code.',
        errors: {},
      };
    }

    case CONFIRM_METHOD_FAILURE: {
      return {
        ...state,
        errors: action.payload.data.errors,
        messageNotificationError: message(
          state.messageNotificationError,
          'Confirmation method failed',
        ),
      };
    }

    case WRONG_PIN_MESSAGE_SHOW: {
      return {
        ...state,
        errors: action.payload.data.errors,
        messageNotificationError: message(
          state.messageNotificationError,
          `${action.payload.data.errors.message}`,
        ),
      };
    }

    case FETCH_PRACTICES_FULFILLED: {
      return {
        ...state,
        practicesList: action.payload.practicesList,
        practicesOptions: action.payload.practicesOptions,
      };
    }

    case FETCH_PRACTICES_REJECTED: {
      return {
        ...state,
        errors: action.payload.data.errors,
        messageNotificationError: message(
          state.messageNotificationError,
          'Could not get list of locations',
        ),
      };
    }

    case GET_EXTERNAL_TOKEN: {
      return {
        ...state,
        externalToken: action.payload,
      };
    }

    default:
      return state;
  }
};
