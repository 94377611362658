import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-google';
import { MdCheckCircle, MdRemoveCircle } from 'react-icons/md';
import ButtonRegistry from '../forms/ButtonRegistry';
import FormElem from '../forms/FormElem';
import InputDate from '../forms/InputDate';
import {
  checkUserStatus,
  validateMethod,
  validateModalClose,
  confirmModalClose,
  confirmCodeMethod,
  registryMessageModalClose,
  hideConfirmCodeErrorMsg,
  existingAccountMsgModalClose,
} from '../../actions/authActions';
import Loading from '../Loading';
import { clearErrors } from '../../actions/homeActions';
import SmallModal from '../SmallModal';
import {
  statesOptions,
  validateFormData,
  formatPhoneNumber,
  sanitizeNames,
  sanitizeAddress,
  sanitizePhone,
} from '../forms/utils';
import {
  Root,
  Title,
  ButtonS,
  ButtonSModalDiv,
  AppointmentContent,
  AppointmentDescription,
  TitleDescription,
} from '../../elements/SignUpForms';
import '../../elements/RecaptCha.css';
import ValidateModal from '../../elements/modals/SignUpForm/ValidateModal';
import ConfirmCodeModal from '../../elements/modals/SignUpForm/ConfirmCodeModal';
/*eslint-disable*/
import { hostIp } from '../../store/api';


class SignUp extends Component {
  constructor(props) {
    super();
    this.state = {
      CID: props.CID,
      phone: '',
      birthday: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      birthdayMessage: '',
      address: '',
      address1: '',
      address2: '',
      city: '',
      stateDefault: '',
      state: '',
      zipcode: '',
      isPasswordConfirmed: false,
      isHuman: true,
      formMsgErrors: {},
      validateOption: false,
    };

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.attachRef = (target) => this.setState({ target });
  }

  componentDidMount() {
    if (this.recaptcha) {
      switch (true) {
        case (process.env.ENVIRONMENT !== 'prod'):
          console.log('Recaptcha hidden...');
          break;
        default:
          loadReCaptcha();
          console.log('Recaptcha loaded...');
          break;
      }
    }
    this.props.sendtitle('');
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.phone !== '') {
      this.setState({ phone: formatPhoneNumber(this.state.phone) });
    }
    const formData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      birthday: this.state.birthday,
      email: this.state.email,
      phone: this.state.phone,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      zipcode: this.state.zipcode,
      state: this.state.state,
      password: this.state.password,
    };
    const validationErrors = Object.entries(this.validateForm(formData));

    if (!validationErrors.length > 0) {
      formData.address = `${this.state.address1} ${this.state.address2}`;
      formData.birthday = this.state.birthday;
      formData.utmMedium = this.props.utmMedium;
      formData.utmSource = this.props.utmSource;
      formData.utmCampaign = this.props.utmCampaign;
      formData.utmTerm = this.props.utmTerm;
      formData.utmContent = this.props.utmContent;
      this.props.dispatch(checkUserStatus(formData));
    } else {
      document.getElementById(`${validationErrors[0][0]}Error`).focus();
    }
  }

  onChange = (value, name) => {
    let inputValue = value;
    if (name === 'firstName' || name === 'lastName' || name === 'city') {
      inputValue = sanitizeNames(value);
    }
    if (name === 'address1' || name === 'address2') {
      inputValue = sanitizeAddress(value);
    }
    if (name === 'phone') {
      inputValue = sanitizePhone(value);
    }
    if (name === 'phone' && value.length > 9 && sanitizePhone(value)) {
      inputValue = formatPhoneNumber(sanitizePhone(value));
    }
    this.setState({ [name]: inputValue });
    this.setState((prevState) => ({
      formMsgErrors: {
        ...prevState.formMsgErrors,
        [name]: '',
      },
    }));
    if (name === 'passwordConfirm') {
      if (this.state.password === value) {
        this.setState({ isPasswordConfirmed: true });
      } else if (this.state.isPasswordConfirmed) {
        this.setState({ isPasswordConfirmed: false });
      }
    }
  };

  onLoadRecaptcha() {
    this.setState({ isHuman: false });
  }

  // eslint-disable-next-line react/sort-comp
  verifyCallback(recaptchaToken) {
    // console.log(recaptchaToken, '<= your recaptcha token');
    if (recaptchaToken) {
      this.setState({ isHuman: true });
    }
  }

  onConfirmCode = (e) => {
    this.setState({ confirmCode: e });
    this.props.dispatch(hideConfirmCodeErrorMsg());
  };

  onConfirmCodeSubmit = () => {
    sessionStorage.removeItem('utmContent');
    sessionStorage.removeItem('utmTerm');
    sessionStorage.removeItem('utmMedium');
    sessionStorage.removeItem('utmSource');
    sessionStorage.removeItem('utmCampaign');
    this.props.dispatch(
      confirmCodeMethod(
        this.state.confirmCode,
        this.props.userRegistryAccountId,
        {
          email: this.state.email,
          password: this.state.password,
        },
        this.props.history,
      ),
    );
  };

  displayFormErrors(formElements, errors) {
    Object.keys(formElements).forEach((key) => {
      this.setState((prevState) => ({
        formMsgErrors: {
          ...prevState.formMsgErrors,
          [key]: errors[key] || '',
        },
      }));
    });
  }

  validateForm(formData) {
    const formErrors = validateFormData(formData);
    this.displayFormErrors(formData, formErrors);
    return formErrors;
  }

  handleDayChange = (birthday) => {
    this.setState((prevState) => ({
      formMsgErrors: {
        ...prevState.formMsgErrors,
        birthday: '',
      },
    }));
    this.setState({ birthday: birthday });
  };

  checkDateSelect = (birthday) => {
    if (birthday === '') {
      this.setState({
        birthdayMessage: this.props.errors.birthday
          ? this.props.errors.birthday
          : 'This field is required',
      });
    } else {
      this.setState({ birthdayMessage: '' });
    }
  };

  clearForm = () => {
    this.setState({
      phone: '',
      birthday: {},
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      birthdayMessage: '',
      address: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      stateDefault: { value: '', text: 'State' },
      zipcode: '',
      isPasswordConfirmed: false,
      isHuman: false,
      validateOption: false,
    });
    this.props.dispatch(clearErrors());
  };

  onValidateAccountSubmit = (e) => {
    e.preventDefault();
    this.props.dispatch(
      validateMethod(
        this.state.validateOption,
        this.props.userRegistryAccountId,
      ),
    );
  };

  handleValidateOptionChange = (event) => {
    this.setState({
      validateOption: event.target.value,
    });
  };

  smClose = () => {
    this.props.dispatch(validateModalClose());
  };

  lgClose = () => {
    this.props.dispatch(confirmModalClose());
  };

  mClose = () => {
    this.props.dispatch(registryMessageModalClose());
  };

  existingAccountMsgModalClose = () => {
    this.props.dispatch(existingAccountMsgModalClose());
  };

  render() {
    /* TODO refactoring input date */
    const {
      themeColor,
      registering,
      fontColor,
      registered,
      validateModal,
      confirmModal,
      registryMessageModal,
      wrongConfirmCodeToolTip,
      verifying,
      existingAccountMsgModal,
      errors,
    } = this.props;
    const { isHuman, isPasswordConfirmed, formMsgErrors } = this.state;

    if (verifying || registering || registered) {
      return (
        <Root>
          <Loading
            height="120px"
            innerText="Verifying information..."
            themeColor={themeColor}
          />
        </Root>
      );
    }
    const _ga = sessionStorage.getItem('_ga')
      ? sessionStorage.getItem('_ga')
      : '';
    return (
      <Root>
        <div className="p-4">
          <ValidateModal
            message="Select one of the options below to validate"
            onChange={(e) => this.handleValidateOptionChange(e)}
            onSubmit={(e) => this.onValidateAccountSubmit(e)}
            show={validateModal}
            onHide={this.smClose}
            email={this.state.email}
            phone={this.state.phone}
          />
          <ConfirmCodeModal
            message="Enter the confirmation code below."
            label="Pin Code"
            size="sm"
            type="text"
            onChange={(e) => this.onConfirmCode(e)}
            onSubmit={(e) => this.onConfirmCodeSubmit(e)}
            errors={wrongConfirmCodeToolTip}
            show={confirmModal}
            onHide={this.lgClose}
          />
          <SmallModal
            title=""
            show={registryMessageModal}
            onHide={this.mClose}
            backdrop="static"
          >
            <div>
              <Row>
                <Col>
                  <MdCheckCircle color={fontColor} size="14em" />
                </Col>
                <Col>
                  <ButtonSModalDiv>
                    <AppointmentDescription>
                      <TitleDescription fontColor={fontColor}>
                        Congratulations!
                      </TitleDescription>
                      <AppointmentContent
                        fontColor={fontColor}
                        style={{ margin: '35px 0 10px' }}
                      >
                        Your account has been created successfully
                      </AppointmentContent>
                    </AppointmentDescription>
                  </ButtonSModalDiv>
                </Col>
              </Row>
            </div>
          </SmallModal>
          <SmallModal
            title=""
            show={existingAccountMsgModal}
            onHide={this.existingAccountMsgModalClose}
            backdrop="static"
          >
            <div>
              <Row>
                <Col>
                  <MdRemoveCircle color={fontColor} size="10em" />
                </Col>
                <Col>
                  <ButtonSModalDiv>
                    <AppointmentDescription>
                      <TitleDescription fontColor={fontColor}>
                        The email submitted is already in use!
                      </TitleDescription>
                      <AppointmentContent
                        fontColor={fontColor}
                        style={{ margin: '35px 0 10px' }}
                      >
                        Please proceed to login or choose another email.
                      </AppointmentContent>
                    </AppointmentDescription>
                  </ButtonSModalDiv>
                </Col>
              </Row>
            </div>
          </SmallModal>
          {!validateModal && !confirmModal ? (
            <form onSubmit={(e) => this.onSubmit(e)}>
              <Row>
                <Row>
                  <Col xs={12}>
                    <p className="text-center py-4 forms-informational-text">
                      If you are a new patient, kindly register first
                      <br />
                      {' '}
                      to schedule your appointment online.
                    </p>
                  </Col>
                </Row>
                <br />
                {/* <row>
							<Col xs={12}>
								<span className='mr-3'>Returning patient?</span>
								<span>
									<a
										href=''
										onClick={(e) => {
											e.preventDefault();
											const { history, dispatch } = this.props;
											history.push({
												pathname: '/login',
												search: sessionStorage.getItem('_ga'),
											});
											dispatch(clearErrors());
										}}
									>
										Login
									</a>
								</span>
							</Col>
							<br />
							<hr />
						</row> */}
              </Row>
              <Row>
                <Col xs={12}>
                  <Row>
                    <Title className="forms-label" fontColor={fontColor}>
                      Personal Information
                    </Title>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={12} sm={6}>
                      <div id="firstNameError" tabIndex="-1" />
                      <FormElem
                        fontColor={fontColor}
                        valid={[
                          {
                            name: 'noEmpty',
                            message:
                              errors.firstname || formMsgErrors.firstName,
                          },
                        ]}
                        onChange={(value, name, type) => this.onChange(value, name, type)}
                        name="firstName"
                        placeholder="First Name"
                        value={this.state.firstName}
                        maxLength={50}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <div id="lastNameError" tabIndex="-1" />
                      <FormElem
                        valid={[
                          {
                            name: 'noEmpty',
                            message: errors.lastname || formMsgErrors.lastName,
                          },
                        ]}
                        onChange={(value, name, type) => this.onChange(value, name, type)}
                        name="lastName"
                        placeholder="Last Name"
                        value={this.state.lastName}
                        maxLength={50}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6}>
                      <div id="birthdayError" tabIndex="-1" />
                      <InputDate
                        fontColor={fontColor}
                        value={this.state.birthday}
                        onDayChange={this.handleDayChange}
                        themeColor={themeColor}
                        errors={errors.birthday || formMsgErrors.birthday}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Title className="forms-label" fontColor={fontColor}>
                  Contact information
                </Title>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <div id="emailError" tabIndex="-1" />
                  <FormElem
                    valid={[
                      {
                        name: 'noEmpty',
                        message: errors.email || formMsgErrors.email,
                      },
                    ]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="email"
                    placeholder="Email"
                    type="email"
                    defaultValue={this.state.email}
                    trim
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <div id="phoneError" tabIndex="-1" />
                  <FormElem
                    valid={[
                      {
                        name: 'noEmpty',
                        message: errors.phone || formMsgErrors.phone,
                      },
                    ]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="phone"
                    placeholder="Mobile Phone, Ex (XXX) XXX-XXXX"
                    value={this.state.phone}
                    maxLength={14}
										// defaultValue={this.state.phone}
                    trim
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <div id="address1Error" tabIndex="-1" />
                  <FormElem
                    fontColor={fontColor}
                    valid={[
                      {
                        name: 'noEmpty',
                        message: errors.address || formMsgErrors.address1,
                      },
                    ]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="address1"
                    placeholder="Address"
                    value={this.state.address1}
                    maxLength={60}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <div id="address2Error" tabIndex="-1" />
                  <FormElem
                    fontColor={fontColor}
                    valid={[]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="address2"
                    placeholder="Address 2"
                    value={this.state.address2}
                    maxLength={60}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <div id="cityError" tabIndex="-1" />
                  <FormElem
                    valid={[
                      {
                        name: 'noEmpty',
                        message: errors.city || formMsgErrors.city,
                      },
                    ]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="city"
                    placeholder="City"
                    value={this.state.city}
                    maxLength={20}
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <div id="zipcodeError" tabIndex="-1" />
                  <FormElem
                    valid={[
                      {
                        name: 'noEmpty',
                        message: errors.zipcode || formMsgErrors.zipcode,
                      },
                    ]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="zipcode"
                    placeholder="ZIP Code"
                    type="number"
                    defaultValue={this.state.zipcode}
                    trim
                  />
                </Col>
                <Col xs={12} sm={3}>
                  <div id="stateError" tabIndex="-1" />
                  <FormElem
                    valid={[
                      {
                        name: 'noEmpty',
                        message: errors.state || formMsgErrors.state,
                      },
                    ]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="state"
                    type="Dropdown"
                    placeholder="State"
                    defaultValue={this.state.stateDefault}
                    dropdownValues={statesOptions}
                    trim
                  />
                </Col>
              </Row>
              <Row>
                <Title className="forms-label" fontColor={fontColor}>
                  Create Password
                </Title>
                <Col xs={12} sm={6}>
                  <FormElem
                    valid={[
                      {
                        name: 'noEmpty',
                        message: this.props.errors.password || '',
                      },
                    ]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="password"
                    type="password"
                    defaultValue={this.state.password}
                    placeholder="Password"
                    trim
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <FormElem
                    valid={[
                      {
                        name: 'passwordMatch',
                        message: !this.state.isPasswordConfirmed
                          ? "Passwords don't match"
                          : '',
                      },
                    ]}
                    onChange={(value, name, type) => this.onChange(value, name, type)}
                    name="passwordConfirm"
                    type="password"
                    defaultValue={this.state.passwordConfirm}
                    placeholder="Confirm password"
                    trim
                    confirmed={isPasswordConfirmed}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-center my-4" xs={12}>
                  <ReCaptcha
                    ref={(el) => {
                      this.recaptcha = el;
                    }}
                    size="normal"
                    data-theme="dark"
                    render="explicit"
                    style={{ display: 'inline-block' }}
                    sitekey={process.env.REACT_APP_ENV_CAPTCHA_KEY}
                    onloadCallback={this.onLoadRecaptcha}
                    verifyCallback={this.verifyCallback}
                  />
                </Col>
              </Row>
              <ButtonS className="text-center">
                <Col xs={12}>
                  <ButtonRegistry
                    disabled={!(isPasswordConfirmed && isHuman)}
                    themeColor={
											isPasswordConfirmed && isHuman ? themeColor : 'red'
										}
                    type="submit"
                    position="Block"
                    bsStyle="Primary"
                  >
                    Register
                  </ButtonRegistry>
                </Col>
              </ButtonS>
            </form>
          ) : null}
        </div>
      </Root>
    );
  }
}
SignUp.propTypes = {
  CID: PropTypes.string,
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  registering: PropTypes.bool.isRequired,
  registered: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  validateModal: PropTypes.bool.isRequired,
  confirmModal: PropTypes.bool.isRequired,
  userRegistryAccountId: PropTypes.number.isRequired,
  registryMessageModal: PropTypes.bool.isRequired,
  wrongConfirmCodeToolTip: PropTypes.bool.isRequired,
  verifying: PropTypes.bool.isRequired,
  existingAccountMsgModal: PropTypes.bool.isRequired,
  utmContent: PropTypes.string,
  utmCampaign: PropTypes.string,
  utmMedium: PropTypes.string,
  utmSource: PropTypes.string,
  utmTerm: PropTypes.string,
  sendtitle: PropTypes.func,
};

SignUp.defaultProps = {
  utmContent: '',
  utmCampaign: '',
  utmMedium: '',
  utmSource: '',
  utmTerm: '',
  CID: '',
};

const mapStateToProps = (state) => ({
  CID: state.homeReducer.CID,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  errors: state.homeReducer.errors,
  registering: state.userReducer.registering,
  registered: state.userReducer.registered,
  validateModal: state.userReducer.validateModal,
  userRegistryAccountId: state.userReducer.userRegistryAccountId,
  confirmModal: state.userReducer.confirmModal,
  registryMessageModal: state.userReducer.registryMessageModal,
  wrongConfirmCodeToolTip: state.userReducer.wrongConfirmCodeToolTip,
  verifying: state.userReducer.verifying,
  existingAccountMsgModal: state.userReducer.existingAccountMsgModal,
  utmContent: state.userReducer.utmContent,
  utmCampaign: state.userReducer.utmCampaign,
  utmMedium: state.userReducer.utmMedium,
  utmSource: state.userReducer.utmSource,
  utmTerm: state.userReducer.utmTerm,
});

export default withRouter(connect(mapStateToProps)(SignUp));
